import api from '@/api';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getToken, onMessage } from 'firebase/messaging';

export const useFcm = () => {
  const getFcm = async preToken => {
    try {
      const nuxtApp = useNuxtApp();
      const config = useRuntimeConfig();

      const token = preToken ? preToken : useCookie('token').value;
      const messaging = nuxtApp.$messaging;

      await signInAnonymously(getAuth());
      const firebaseToken = await getToken(messaging, {
        vapidKey: config.public.fcmVapidKey,
      });

      if (firebaseToken && token) {
        const subDomain = useCookie('subagent_userInfo').value.subDomain;
        await api.post(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/set/fcm-token`, {
          fcm_token: firebaseToken,
          type: 1,
        });
      }

      onMessage(messaging, () => {
        nuxtApp.$store.dispatch('notification/getCountNumber');
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { getFcm };
};
