<template>
  <div class="alert-message">
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 24" fill="none">
      <path
        d="M12.9383 16.8652C12.2148 16.8652 11.6084 17.4805 11.6084 18.2145C11.6084 18.9484 12.2148 19.5637 12.9383 19.5637C13.6352 19.5637 14.2682 18.9484 14.2363 18.2468C14.2682 17.4751 13.6671 16.8652 12.9383 16.8652Z"
        fill="#D80027"
      />
      <path
        d="M25.2485 21.647C26.0837 20.1844 26.089 18.4412 25.2592 16.984L16.9286 2.34765C16.104 0.874297 14.6145 0 12.9441 0C11.2737 0 9.78424 0.879694 8.95969 2.34225L0.618438 16.9948C-0.211432 18.4682 -0.206112 20.2222 0.634397 21.6847C1.46427 23.1311 2.94846 24 4.60819 24H21.2481C22.9132 24 24.408 23.1203 25.2485 21.647ZM23.4398 20.5892C22.977 21.3987 22.1578 21.879 21.2428 21.879H4.60287C3.69853 21.879 2.88462 21.4095 2.43245 20.6161C1.97495 19.812 1.96964 18.8514 2.42713 18.0418L10.7684 3.39465C11.2205 2.59051 12.0291 2.11558 12.9441 2.11558C13.8538 2.11558 14.6677 2.59591 15.1199 3.40005L23.4558 18.0472C23.9027 18.8352 23.8973 19.785 23.4398 20.5892Z"
        fill="#D80027"
      />
      <path
        d="M12.6087 7.39356C11.9757 7.57705 11.582 8.15992 11.582 8.86691C11.6139 9.29327 11.6405 9.72502 11.6725 10.1514C11.7629 11.7758 11.8533 13.3679 11.9438 14.9924C11.9757 15.5429 12.3959 15.9422 12.9385 15.9422C13.4812 15.9422 13.9067 15.5159 13.9333 14.96C13.9333 14.6254 13.9333 14.3178 13.9652 13.9778C14.0238 12.9362 14.0876 11.8946 14.1461 10.853C14.178 10.1784 14.2365 9.50375 14.2685 8.82913C14.2685 8.58627 14.2365 8.3704 14.1461 8.15452C13.8748 7.55007 13.2418 7.24245 12.6087 7.39356Z"
        fill="#D80027"
      />
    </svg>
    <span class="text"><slot /></span>
  </div>
</template>

<style lang="scss">
.alert-message {
  margin-bottom: 20px;
  text-align: start;
  background-color: #ffebeb;
  color: #d60040;
  border: 1px solid #d60040;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
}
</style>
